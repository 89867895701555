import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import '../tools/css/Navbar.css';
import LogoIDEAS from '../tools/Imagenes/ideas logo.png'

const Token =sessionStorage.getItem('token');


var queryString_user = window.location.search;
var urlParams_user = new URLSearchParams(queryString_user);
var usuario = urlParams_user.get('user');

var queryString_pass = window.location.search;
var urlParams_pass = new URLSearchParams(queryString_pass);
var contra = urlParams_pass.get('pass');

//alert(usuario)
//alert(contra)

//permite validar el token para las conexiones con axios
axios.interceptors.request.use(
  config =>{
    config.headers.authorization = `Bearer ${Token}`;
    return config;
  },
  error =>{
    return Promise.reject(error);
  }
)



class Navbar extends Component{


  componentDidMount(){  

      if(!sessionStorage.getItem('id_user')){
        window.location.href="/Login_user";
      }
  }

  render(){
  
    return(
        
      <div>
         <nav className="main-header navbar navbar-expand navbar-white">
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
    </li>
    <li className="nav-item d-none d-sm-inline-block">
    <a  href="https://appideas.com.co/" target="_blank" className="navbar-brand"><img src={LogoIDEAS} alt="AdminLTE Logo" width="120" className="brand-image img-rounded" /> </a>
    </li>
    
  </ul> 
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto">
    
   
    {/* Notifications Dropdown Menu */}
    <li className="nav-item dropdown">
      <a className="nav-link" data-toggle="dropdown" href="#">
        <i className="far fa-bell" />
        <span className="badge badge-warning navbar-badge">{}</span>
      </a>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span className="dropdown-item dropdown-header">15 Notifications</span>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-envelope mr-2" /> 4 new messages
          <span className="float-right text-muted text-sm">3 mins</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-users mr-2" /> 8 friend requests
          <span className="float-right text-muted text-sm">12 hours</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item">
          <i className="fas fa-file mr-2" /> 3 new reports
          <span className="float-right text-muted text-sm">2 days</span>
        </a>
        <div className="dropdown-divider" />
        <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
      </div>
    </li>    
   
  </ul>
  
</nav>

      </div>

    )

  }
}

export default Navbar;