import React from "react";
import { BrowserRouter as Router, Switch, Route, Link  } from "react-router-dom";


import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Sidebar_user from "./components/Sidebar_user";
import Sidebar_anfitrion from "./components/Sidebar_anfitrion";
import Home from "./pages/Home";
import Home_user from "./pages/Home_user";
import Home_anfitrion from "./pages/Home_anfitrion";
import Puntos_Bisinu from "./pages/Puntos_Bisinu";
import Mapa from "./pages/Mapa";
import Registrar from "./pages/Registrar"
import Login from './pages/Login'
import Login_user from './pages/Login_user'
import Prestamos_user from "./pages/Prestamos_user";
import Reportar_taller from "./pages/Reportar_taller";
import Usuario_taller from "./pages/Usuario_taller";
import Mi_cuenta from "./pages/Mi_cuenta";
import Puntos_web from "./pages/Puntos_web";
import Citas from "./pages/Citas";
import Historias_clinica from "./pages/Historias_clinica";
import Visitas from "./pages/Visitas";
import Imagenes_DX from "./pages/Imagenes_DX";
import Sedes from "./pages/Sedes";
import Notificaciones_firebase from "./pages/Notificaciones_firebase";

import Pagina404 from "./pages/Pagina404";

function App(){
    return(
       
        <Router>
            
            <Switch>

              <Route exact path="/" >
               
                <Login/>
             
              </Route>              

              <Route path="/Registrar">
               
                <Registrar/>
              
              </Route> 

              <Route path="/Puntos_Web">
               
                <Puntos_web/>
              
              </Route>


              <Route path="/Home">
                <Navbar/>
                <Sidebar/>
                <Home/>
                <Footer/>
              </Route>  

              <Route path="/Mapa">
                <Navbar/>
                <Sidebar/>
                <Mapa/>
                <Footer/>
              </Route>

              <Route path="/gestion_BISINU">
                <Navbar/>
                <Sidebar/>
                <Puntos_Bisinu/>
                <Footer/>
              </Route>

              <Route path="/usuario_taller">
                <Navbar/>
                <Sidebar/>
                <Usuario_taller/>
                <Footer/>
              </Route>  

              <Route exact path="/Login_user" >
               
                <Login_user/>
             
              </Route>

              <Route path="/home_user">
                <Navbar/>
                <Sidebar_user/>
                <Home_user/>
                
              </Route>

              <Route path="/Historias_clinicas">
                <Navbar/>
                <Sidebar_user/>
                <Historias_clinica/>
                
              </Route>

              <Route path="/Visitas">
                <Navbar/>
                <Sidebar_user/>
                <Visitas/>
                
              </Route>

              <Route path="/Imagenes_DX">
                <Navbar/>
                <Sidebar_user/>
                <Imagenes_DX/>
                
              </Route>

              <Route path="/Sedes">
                <Navbar/>
                <Sidebar_user/>
                <Sedes/>
                
              </Route>

              <Route path="/Citas">
                <Navbar/>
                <Sidebar_user/>
                <Citas/>
                
              </Route>

              <Route path="/mi_cuenta_user">
                <Navbar/>
                <Sidebar_user/>
                <Mi_cuenta/>
                
              </Route>


              <Route path="/prestamos_user">
                <Navbar/>
                <Sidebar_user/>
                <Prestamos_user/>
                
              </Route> 

              <Route path="/home_anfitrion">
                <Navbar/>
                <Sidebar_anfitrion/>
                <Home_anfitrion/>
                
              </Route>

              <Route path="/mi_cuenta_anfitrion">
                <Navbar/>
                <Sidebar_anfitrion/>
                <Mi_cuenta/>
                
              </Route>

              <Route path="/prestamos_anfitrion">
                <Navbar/>
                <Sidebar_anfitrion/>
                <Prestamos_user/>
                
              </Route>

              <Route path="/reportar_taller">
                <Navbar/>
                <Sidebar_anfitrion/>
                <Reportar_taller/>
                
              </Route>

              <Route path="/Notificaciones_firebase_IDEAS">
               
                <Notificaciones_firebase/>
              
              </Route>   

              <Route  path="**">              
               <Pagina404/>                   
              </Route>  
                          
             
              


            </Switch>
           

        </Router>
    )
}

export default App;