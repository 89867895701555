import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Login_user.css';

import LogoBici from '../tools/Imagenes/BISINUPNG.png'

import $ from 'jquery'; // <-to import jquery

var queryString_user = window.location.search;
var urlParams_user = new URLSearchParams(queryString_user);
var tipoid_usuario = urlParams_user.get('tipoiduser');

var queryString_pass = window.location.search;
var urlParams_pass = new URLSearchParams(queryString_pass);
var numid_usuario = urlParams_pass.get('numiduser');

var queryString_pass = window.location.search;
var urlParams_pass = new URLSearchParams(queryString_pass);
var contra = urlParams_pass.get('pass');

var queryString_sesion = window.location.search;
var urlParams_sesion = new URLSearchParams(queryString_sesion);
var sesion = urlParams_sesion.get('session');



const ApiURL="https://ideasapidevelop.azurewebsites.net";

var tipo_id='0';
var sexo_usua='0';
var id_departamento='0';
var avatar=[];
var files_view='';

class Login_user extends Component {

    constructor()
    {
     super()
      this.state={
        show:false,
        Tipo_id:[] , 
        Departamentos: [],
        Municipios:[],
        loading: false,
        loading_button: true,
        active_button: true,
        form:{   
          email_user:'',  
          pass_login:'',
          codigo_email_user:'',   
          email_recuperacion:'',
          codigo_recuperacion:'',
          pass_1:'',
          pass_2:''
         
          }     
      }
    }

    handleChange=async e=>{
     
        await this.setState({
           form:{
                ...this.state.form,
                [e.target.name]: e.target.value
                }
           })
          
           console.log(this.state.form);
           //this.Selectdrop();
    }


  GettipoID = (id) =>{
        //this.setState({tipoid:id.target.value})
        console.log(id.target.value)
        tipo_id=(id.target.value)     
  }
  
  Getsexo = (sx) =>{
      //this.setState({tipoid:id.target.value})
      console.log(sx.target.value)
      sexo_usua=(sx.target.value)     
  }
  
  Get_iddepar = (dp) =>{
    //this.setState({tipoid:id.target.value})
    console.log(dp.target.value)
    id_departamento=(dp.target.value)     
  }

  GetImg = (i) =>{      
  
    avatar=i.target.files[0]
    //files_view=URL.createObjectURL(i.target.files[0]);
    
    //$('#modal_documento').modal('show');
    //alert(avatar)
  }


  componentDidMount(){  

    $('#modal_loading').modal('show');

    this.Iniciar_Sesion_Parametro();
    
    $('#recuperar_paso2').hide();

    if(sessionStorage.getItem('id_user')){
      window.location.href="/home_user";
    }
    //this.Sweetaler();
       
  }

  Sweetaler(){
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: true,
      timer: 5000
    })
  }


  handleModal_recuperar_paso2()
  { 
    $('#recuperar_paso2').show();
    $('#recuperar_paso1').hide();
   
  }


  //<- muestra modal para recuperar contraseña
  handleModal_recuperar_contraseña()
  { 
    this.setState({
      form:{
        email_user:'',  
          pass_login:this.state.form.pass_login,
          codigo_email_user:this.state.form.codigo_email_user,   
          email_recuperacion:this.state.form.email_recuperacion,
          codigo_recuperacion:this.state.form.codigo_recuperacion,
          pass_1:this.state.form.pass_1,
          pass_2:this.state.form.pass_2         
      }
    })

    $('#recuperar_paso2').hide();
    $('#recuperar_paso1').show();
    $('#modal_recuperar_contra').modal('show');
  }


  Iniciar_Sesion=async()=>{

    if(navigator.onLine) {
     
      if(this.state.form.email_user.length==0 || this.state.form.email_user=='' || this.state.form.pass_login.length==0 || this.state.form.pass_login==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa tu correo electrónico y contraseña para iniciar sesión',
          showConfirmButton: true,
          timer: 10000
        })
  
      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })
  
        await axios.post(ApiURL+"/api/Auth/Login",
  
          {
            email:this.state.form.email_user,
            password: this.state.form.pass_login.toLowerCase(),
          
          })  
  
        .then(response=>{
          console.log(response)
          //var  status  = response.status;
          //alert(status); 
          //mensaje  = response.data.message;
                
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
         
                var  token  = response.data.data.token;
                var  nombre_usua  = response.data.data.user.name1 +" "+response.data.data.user.name2+" "+response.data.data.user.lastName1+" "+response.data.data.user.lastName2;
                var  avatar  = response.data.data.user.avatar;
                //alert(nombre_usua); 
                sessionStorage.setItem('token',token)
                sessionStorage.setItem('nombre_usua',nombre_usua)
                sessionStorage.setItem('avatar',avatar)
  
                window.location.href="/home_user";            
                       
                
                
           
          //var  dato  = response.data.token;
          //alert(dato);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          
        })
        .catch(error=>{
  
          if (error.response) {
           
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);

      
       })
        
      }

    } else {
  
     
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Atención',
        text:'Compruebe su conexión a internet e intente nuevamente',
        showConfirmButton: true,
        timer: 10000
      })
     
    }

        

  }

  Iniciar_Sesion_Parametro=async()=>{

    if(navigator.onLine) {
     
     

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })
  
        await axios.post(ApiURL+"/api/Auth/BasicLogin",  
          
          {
            typeId: tipoid_usuario,
            identification: numid_usuario,
            password: contra
          })  
  
        .then(response=>{
          console.log(response.data.user)
          //var  status  = response.status;
          //alert(status); 
          //mensaje  = response.data.message;
                
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
         
                var  token  = response.data.token;
                var  nombre_usua  = response.data.user.name1 +" "+response.data.user.name2+" "+response.data.user.lastName1+" "+response.data.user.lastName2;
                //var  avatar  = response.data.data.user.avatar;
                var id_user = response.data.user.id
                var tipo_id_user = response.data.user.typeId
                var num_id_user = response.data.user.identification

                if(num_id_user==='1065003053'){
                  //alert("orientador"); 
                  var rol="orientador"
                }
                //alert(nombre_usua); 
                sessionStorage.setItem('token',token)
                sessionStorage.setItem('nombre_usua',nombre_usua)
                //sessionStorage.setItem('avatar',avatar)
                sessionStorage.setItem('id_user',id_user)
                sessionStorage.setItem('tipo_id_user',tipo_id_user)
                sessionStorage.setItem('num_id_user',num_id_user)
                sessionStorage.setItem('rol_user',rol)
  
                window.location.href="/home_user";            
                       
                
                
           
          //var  dato  = response.data.token;
          //alert(dato);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          
        })
        .catch(error=>{
  
          if (error.response) {
           
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
           
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);

      
       })
        
    

    } else {
  
     
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Atención',
        text:'Compruebe su conexión a internet e intente nuevamente',
        showConfirmButton: true,
        timer: 10000
      })
     
    }

        

   }

  Enviar_Codigo_Recupera=async()=>{

    if(this.state.form.email_recuperacion.length==0 || this.state.form.email_recuperacion==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa un correo electrónico valido',
        showConfirmButton: true,
        timer: 10000
      })


    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })
      

      await axios.post(ApiURL+"/api/Users/RecoveryPassword",

        {          
          email: this.state.form.email_recuperacion
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Atención',
          html: mensaje,
          showConfirmButton: true,
          timer: 10000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        $('#recuperar_paso1').hide();
        $('#recuperar_paso2').show();
        
               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })         

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }      

  }



  Validar_Password(){

    if(this.state.form.pass_1.length==0 || this.state.form.pass_1=='' || this.state.form.pass_2.length==0 || this.state.form.pass_2==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Ingresa una contraseña y confirmala',
        showConfirmButton: true,
        timer: 10000
      })

    }else{

      if(this.state.form.pass_1 != this.state.form.pass_2){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Las contraseña no coinciden, verifique y vuelva a intentar',
          showConfirmButton: true,
          timer: 10000
        })

      }else{

        this.Cambiar_Contraseña();

      }     

    }

  }




  Cambiar_Contraseña=async()=>{

    if(this.state.form.pass_1.length==0 || this.state.form.pass_1=='' || this.state.form.pass_2.length==0 || this.state.form.pass_2=='' || this.state.form.codigo_recuperacion.length==0 || this.state.form.codigo_recuperacion==''){

      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Atención',
        text:'Faltan datos por ingresar',
        showConfirmButton: true,
        timer: 10000
      })

    }else{  
      
      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/Users/ChangePassword",

        {          
          code: this.state.form.codigo_recuperacion,
          newPassword: this.state.form.pass_1
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Atención',
          html: mensaje,
          showConfirmButton: true,
          timer: 10000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        $('#modal_recuperar_contra').modal('hide');

               
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })


      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);
          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

    }

  }


  mostrarPassword(){
    var cambio = document.getElementById("pass_login");
  if(cambio.type == "password"){
    cambio.type = "text";
    $('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
  }else{
    cambio.type = "password";
    $('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
  }
  }



  render(){  

        const {form} = this.state;

    return(

    <div>     

       {/*aqui va codigo_login_user */}

      <div className="modal fade" id="modal_loading" data-backdrop="static">
            <div className="modal-dialog  modal-dialog-centered">
              <div className="modal-content">
                

                <div className="modal-body">

                <div className="d-flex justify-content-center">
          
                    {this.state.loading?false: <ReactBootStrap.Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />}
            
                </div>

                <div className="d-flex justify-content-center">                                                
                      <spam id="spamloader"> <h2>Cargando...</h2> </spam>                           
                </div> 
                

                </div>
                
                 

              </div>  
            </div>
      </div>

    </div>


    )

  }

}


export default Login_user;       