import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import * as ReactBootStrap from 'react-bootstrap';
import '../tools/css/Login.css';

import LogoCLEM from '../tools/Imagenes/Logotipo-Clinica.png'
import LogoIDEAS from '../tools/Imagenes/ideas logo.png'

import $ from 'jquery'; // <-to import jquery



const ApiURL="https://ideasapidevelop.azurewebsites.net";

var tipo_id='0';
var sexo_usua='0';
var id_departamento='0';
var avatar=[];
var files_view='';

class Login extends Component {

    constructor()
    {
     super()
      this.state={
        show:false,
        Tipo_id:[] , 
        Departamentos: [],
        Municipios:[],
        loading: false,
        loading_button: true,
        active_button: true,
        form:{   
          idenificacion_user:'',  
          codigo_email_user:'',   
          pass_1:'',
          pass_2:'',
          num_id_user:'',     
          nom1_user:'',  
          nom2_user:'',   
          apell1_user:'',
          apell2_user:'',  
          fecha_nac_user:'',  
          dir_user:'',
          cel_user:'',
          codigo_cel_user:''
          }     
      }
    }

    handleChange=async e=>{
     
        await this.setState({
           form:{
                ...this.state.form,
                [e.target.name]: e.target.value
                }
           })
          
           console.log(this.state.form);
           //this.Selectdrop();
    }


  GettipoID = (id) =>{
        //this.setState({tipoid:id.target.value})
        console.log(id.target.value)
        tipo_id=(id.target.value)     
  }
  
  Getsexo = (sx) =>{
      //this.setState({tipoid:id.target.value})
      console.log(sx.target.value)
      sexo_usua=(sx.target.value)     
  }
  
  Get_iddepar = (dp) =>{
    //this.setState({tipoid:id.target.value})
    console.log(dp.target.value)
    id_departamento=(dp.target.value)     
  }

  GetImg = (i) =>{      
  
    avatar=i.target.files[0]
    //files_view=URL.createObjectURL(i.target.files[0]);
    
    //$('#modal_documento').modal('show');
    //alert(avatar)
  }


  componentDidMount(){  

   
    if(sessionStorage.getItem('token')){
      window.location.href="/home";
    }

    this.Cargar_tipo_id();
    
    
    //this.Sweetaler();
       
  }

  Sweetaler(){
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Your work has been saved',
      showConfirmButton: true,
      timer: 5000
    })
  }


  //<- muestra modal para crear sede
  handleModalnewpunto()
  { 
    $('#modal-newpunto').modal('show');
  }


  Validar_paso1(){

    $('#paso1').hide(); 
    $('#paso2').show(); 
    $('#paso3').hide();
    $('#paso4').hide(); 
    $('#paso5').hide(); 
    $('#paso6').hide(); 

  }

 


  Cargar_tipo_id=async()=>{    
    
    await axios.get(ApiURL+"/api/TypeIdentification/ListTypeIdentification/paciente")
      .then((response) => {
        console.log(response);
        this.setState({Tipo_id: response.data})
      })
      .catch((error) => {
        console.log(error);
      })
  }


  Cargar_departamentos=async()=>{    
    
    await axios.get(ApiURL+"/api/Departments")
      .then((response) => {
        console.log(response.data);
        this.setState({Departamentos: response.data.data})
      })
      .catch((error) => {
        console.log(error);
      })
  }



   Iniciar_Sesion=async()=>{

    if(navigator.onLine) {
     
      if(tipo_id=='0' ||this.state.form.idenificacion_user.length==0 || this.state.form.idenificacion_user=='' || this.state.form.pass_1.length==0 || this.state.form.pass_1==''){

        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Atención',
          text:'Ingresa tu número de ifentificación y contraseña para iniciar sesión',
          showConfirmButton: true,
          timer: 10000
        })
  
      }else{

        this.setState({ loading_button: this.state.loading_button = false })
        this.setState({ loading_button: this.state.active_button = false })
  
        await axios.post(ApiURL+"/api/Auth/BasicLogin",
  
          {
            typeId: tipo_id,
            identification: this.state.form.idenificacion_user,
            password: this.state.form.pass_1       
          })  
  
        .then(response=>{
          console.log(response)
          //var  status  = response.status;
          //alert(status); 
          //mensaje  = response.data.message;
                
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
         
            var  token  = response.data.token;
            var  nombre_usua  = response.data.user.name1 +" "+response.data.user.name2+" "+response.data.user.lastName1+" "+response.data.user.lastName2;
            //var  avatar  = response.data.data.user.avatar;
            var id_user = response.data.user.id
            var tipo_id_user = response.data.user.typeId
            var num_id_user = response.data.user.identification

            if(num_id_user==='1065003053'){
              //alert("orientador"); 
              var rol="orientador"
            }
            //alert(nombre_usua); 
            sessionStorage.setItem('token',token)
            sessionStorage.setItem('nombre_usua',nombre_usua)
            //sessionStorage.setItem('avatar',avatar)
            sessionStorage.setItem('id_user',id_user)
            sessionStorage.setItem('tipo_id_user',tipo_id_user)
            sessionStorage.setItem('num_id_user',num_id_user)
            sessionStorage.setItem('rol_user',rol)

            window.location.href="/home_user";            
                       
                
                
           
          //var  dato  = response.data.token;
          //alert(dato);
          //const [text, setText] = LocalStorage(text, '')
          //setText(dato);
                 
          //window.location.href="/home";
          
        })
        .catch(error=>{
  
          if (error.response) {
           
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            //console.log(error.response.data.message);
            //console.log(error.response.status);
            //console.log(error.response.headers);
            var mensaje=error.response.data.message
            
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: 'Atención',
              text: mensaje,
              showConfirmButton: true,
              timer: 10000
            })
  
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
  
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
            this.setState({ loading_button: this.state.loading_button = true })
            this.setState({ active_button: this.state.active_button = true })
        }
        //console.log(error.config);

      
       })
        
      }

    } else {
  
     
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Atención',
        text:'Compruebe su conexión a internet e intente nuevamente',
        showConfirmButton: true,
        timer: 10000
      })
     
    }

        

   }

  


  mostrarPassword(){
    var cambio = document.getElementById("pass_1");
  if(cambio.type == "password"){
    cambio.type = "text";
    $('.icon').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
  }else{
    cambio.type = "password";
    $('.icon').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
  }
  }




  render(){  

        const {form} = this.state;

    return(

    <div>     

      <body  id="body_login">
        

        <div className="row"> 

                <div className="col-md-7">
                
                </div>

              <div className="col-md-5">

                  <div className="card" id="card-login">
                    
                    <div className="text-center">
                      
                      <a href="https://www.clinicalaesperanza.co/" target="_blank" className="h1"><img id="imge_logo" src={LogoCLEM} alt="IDEAS" width="50%" className="img-fluid img-rounded" /></a>
                      <br></br>
                      <b> <h3 id="text-registrarse">Bienvenido</h3></b>
                     
                    </div>
    
                    {/*Vista de registro paso 1 */}
                    <div className="card-body" id="paso1">   

                          <div className="row d-flex justify-content-center">

                            <div className="col-md-8">



                                <div className="form-group">                    
                               
                                  <div className="input-group mb-3">

                                    <div className="input-group-append">
                                      <div className="input-group-text">
                                        <span id="iconos" className="fa-solid fa-address-card" />
                                      </div>
                                    </div>
                                    <select onChange={this.GettipoID}  className="form-control" id="id_tipoid" name="id_tipoid"  >

                                            <option  value="0"> Tipo de identificación</option> ,
                                            {this.state.Tipo_id.map(item=>                              
                                            <option key={item.id} value={item.code}>  {item.description ? (item.description).replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : '' }  </option>  
                                            )}
                                                  
                                    </select>
                                    
                                  </div>     
                                </div>

                                <div className="form-group">                    
                               
                                  <div className="input-group mb-3">

                                    <div className="input-group-append">
                                      <div className="input-group-text">
                                        <span id="iconos" className="fas fa-user" />
                                      </div>
                                    </div>
                                    <input type="email" className="form-control" autocomplete="nope" id="idenificacion_user" name="idenificacion_user" placeholder="Identificación" onChange={this.handleChange} />
                                    
                                  </div>     
                                </div>

                                <div className="form-group">                    
                                 
                                  <div className="input-group mb-3">

                                    <div className="input-group-append">
                                      <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                      </div>
                                    </div>
                                    <input type="password" className="form-control" autocomplete="nope" id="pass_1" name="pass_1" placeholder="Contraseña" onChange={this.handleChange} />
                                    
                                  </div>     
                                </div>
          
                                <div className="row">
          
                                  <p className="col-6 text-center">  
                                      <div className="form-check">
                                      <input className="form-check-input" type="checkbox" id="flexCheckDefault" onClick={()=> this.mostrarPassword()}></input>
                                        <label className="form-check-label" htmlFor="flexCheckDefault" id="checkpass">
                                         Mostrar contraseña
                                        </label>
                                      </div>                        
                                  </p>

                                  <p className="col-6 text-center" >
                                    <a href="#" onClick={()=> this.Validar_paso1()} id="checkpass">Olvidé mi contraseña</a>
                                  </p>
          
                                </div>
                                
                                <br></br>

                                <div className="row"> 
                                  
                                  <div className="col-12">
                                    <button type="button" id="btn_registrarse" className="btn btn-primary btn-block" disabled={!this.state.active_button} onClick={()=> this.Iniciar_Sesion()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  <span className="fas fa-user" /> Iniciar sesión</button>
                                  </div>
                                 
                                </div>

                                <br></br>
                                <br></br>

                                <div className="row d-flex justify-content-center">
          
                                  <p className="col-10 text-center" >
                                    ¿No tienes cuenta?<a href="#" onClick={()=> this.Validar_paso1()} > Registrar aquí</a>
                                  </p>


                                </div>

                                <div className="row d-flex justify-content-center">
          
                                  <p className="col-12 text-center" >
                                  <label>By <a href="https://appideas.com.co/" target="_blank"><img  src={LogoIDEAS} alt="IDEAS" width="25%" className="img-fluid img-rounded" /></a> </label>
                                  
                                  
                                  </p>


                                </div>

                            </div>
                            
                          </div>              
 
                    </div>
    
    
                  </div>
                
              </div>          

        </div>
       

      </body>

          

    </div>


    )

  }

}


export default Login;       