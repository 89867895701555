import React, {Component} from "react";
import axios from "axios";
import Swal from "sweetalert2"
import Moment from 'react-moment';
import 'moment/locale/es';
import moment from "moment";
import '../tools/css/Historia_clinica.css';

import LogoCLEM from '../tools/Imagenes/Logotipo-Clinica.png'
import Logoestaciones from '../tools/Imagenes/Iconomapa.png'
import LogoBisinu from '../tools/Imagenes/BISINU.png'
import Slider1 from '../tools/Imagenes/slider1.jpg'
import Slider2 from '../tools/Imagenes/slider2.jpg'
import Slider3 from '../tools/Imagenes/slider3.jpg'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import AwesomeSlider from 'react-awesome-slider';
import Estilo from 'react-awesome-slider/dist/styles.css';

import * as ReactBootStrap from 'react-bootstrap';
import Map from "../components/Map";
import $ from 'jquery'; // <-to import jquery

var queryString_idvisita = window.location.search;
var urlParams_idvisita = new URLSearchParams(queryString_idvisita);
var id_visita = urlParams_idvisita.get('id_visita');

//alert(id_visita)

//var url_epicrisis="https://190.60.244.250:8443/Epicrisis_SISS.jsp?numid_paciente="+id_visita

const ApiURL="https://ideasapidevelop.azurewebsites.net";


var id_estacion=0;


const containerStyle = {
  width: '100%',
  height: '600px'
};

const center = {
  lat: 8.7715339,
  lng: -75.8807398
};

const centers = [
  {
  lat: 8.743841290088483,
  lng: -75.89315296335327
  },
  {
    lat: 8.749379159242933,
    lng: -75.87960238756898
  },
  {
    lat: 8.757374479037056,
    lng: -75.87484951330114
  },
  {
    lat: 8.800722867803866,
    lng: -75.85021616085848
  }
];

const onLoad = marker => {
  console.log('marker: ', marker)
}



class Historias_clinica extends Component {

  constructor()
    {
     super()
      this.state={
        Estaciones:[] , 
        Estudios:[],
        loading: false,
        loading_button: true,
        active_button: true,
       
        form:{   
          nom_estacion:'',  
          fecha_prestamo:moment().format("DD/MM/YYYY"),
          hora_prestamo:moment().format("hh:mm"),
          obser:''
          
          }     
      }
    }

    handleChange=async e=>{
     
      await this.setState({
         form:{
              ...this.state.form,
              [e.target.name]: e.target.value
              }
         })
        
         console.log(this.state.form);
         //this.Selectdrop();
  }

  componentDidMount(){  
    
    $('#card_historias').hide();     
    //$('#google_maps').hide();
    //this.Cargar_Estaciones_stock();

    if(!sessionStorage.getItem('token')){
      window.location.href="/Login_user";
    }
  }

  Sweetaler_solicitud(){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  handleModal_acercade()
  {   
    $('#myModal_info').modal('show');
  }

  handleModal_estaciones()
  { 
   
    $('#modal_estaciones').modal('show');
  }


  handleModal_solicitud_prestamo()
  { 
   
    $('#modal_solicitud_prestamo').modal('show');
  }

  Cargar_Estaciones_stock(){
      
    //this.empty_page_hide();
    
    this.setState({ loading: this.state.loading = false })
  
    axios.get(ApiURL+"/api/Stations/GetStationsStock")
    .then((response) => {
      console.log(response);
      this.setState({Estaciones:response.data.data})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

      
        //$('#card_historias').show();      
     

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }

  Seleccionar_Estacion=(estacion)=>{
   
    id_estacion=estacion.stationId
  
    //alert(id_estacion)     
    
    //alert(muni)
    
    this.setState({
      form:{
        nom_estacion:estacion.name,
        fecha_prestamo:moment().format("DD/MM/YYYY"),
        hora_prestamo:moment().format("hh:mm"),
        obser:''   
      }
    })      
    //var  dato  = servicio.id;
    //alert(this.state.form.fecha_prestamo);
    //alert(this.state.form.hora_prestamo);
  }

  Crear_solicitud=async()=>{

      this.setState({ loading_button: this.state.loading_button = false })
      this.setState({ active_button: this.state.active_button = false })

      await axios.post(ApiURL+"/api/BikeRequests/Request",

        {
          stationId: id_estacion,
          annotation: this.state.form.obser,
         
        })  
      .then(response=>{
        console.log(response.data)
        var  mensaje  = response.data.message;
        //alert(mensaje);
        //const [text, setText] = LocalStorage(text, '')
        //setText(dato);
               
        //window.location.href="/home";
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: '¡Muy Bien!',
          html: mensaje,
          showConfirmButton: true,
          timer: 20000
        })

        this.setState({ loading_button: this.state.loading_button = true })
        this.setState({ active_button: this.state.active_button = true })

        this.componentDidMount();
        $('#modal-newbicicleta').modal('hide');
        this.Cargar_Bisicletas();

        
      })
      .catch(error=>{
       
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          //console.log(error.response.data.message);
          //console.log(error.response.status);
          //console.log(error.response.headers);
          var mensaje=error.response.data.message
          
          Swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Atención',
            text: mensaje,
            showConfirmButton: true,
            timer: 10000
          })

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })

      } else {
          // Something happened in setting up the request and triggered an Error
          console.log('Error', error.message);

          this.setState({ loading_button: this.state.loading_button = true })
          this.setState({ active_button: this.state.active_button = true })
      }
      //console.log(error.config);
      this.setState({ loading_button: this.state.loading_button = true })
      this.setState({ active_button: this.state.active_button = true })
    })

  }

  Cargar_estudios(){
      
    //this.empty_page_hide();
    const token_go = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImRjZmQ4Yjg2ODJkYzAyMmYyOGQ3MjUzMGRmYjEwZjc2MTE2MTg4M2Q3MDA5MmQwYzMxMDk0MWMwZDRkMWQ2Njk5YzIwYjU4ZTc0NWQ1YTE4In0.eyJhdWQiOiIyIiwianRpIjoiZGNmZDhiODY4MmRjMDIyZjI4ZDcyNTMwZGZiMTBmNzYxMTYxODgzZDcwMDkyZDBjMzEwOTQxYzBkNGQxZDY2OTljMjBiNThlNzQ1ZDVhMTgiLCJpYXQiOjE3MTg3Mjg0NjAsIm5iZiI6MTcxODcyODQ2MCwiZXhwIjoxNzUwMjY0NDYwLCJzdWIiOiIiLCJzY29wZXMiOltdfQ.NLGc-SHDaGoaUIn_AFkyGo7vwkF-V65PefzlGKIXfZORxwA0OvpCUNWz-9ILHXDR0F3hq3kBu5ykSi7RUeVxU4XcjO5aUYC-aMirwozAg-1SWdmrzgCPPGKVYQCP9k808a9JULfMgzAdKXtTSRRwqrHmxI96MLTDMm2wa0Kf_32j4mIymAZlmObGESrPU4ScRL3Hm1Fdi_Ug4yW7khdotLOsxcKzmyHwQWfUD3azh3r9iWl91TmgXLmg9wT_a9E6978I7Aihcw9OTzxV1WREwwmCtfUwlUMeMhOPO-YlJCm8F0mqcL6pOmKH7TQaCVMzpgZSIKSRhGi-r1_aDQdSOdZ_nmmYQPW629ttCBk1pOwHFximBgI5eOkSTsmAzdLCf3iBY7uo9S7vVJxRahVAgcWFxmuOZsy7nIgBzxPHiZUlc-pnO_wjhUiTzEjd2q7PB1yttGT1YEYKHRP_okETIVn2yrkPoasjKU40Ut8xlhir7Cfynec-lgIT9s2cHaKR2qGkPIx0zjsPF7Vl2rFDD4m2r8ZRyBqOQTYRjt0X11W3K2WEwU3pb5-sfDXYnRkfVadvZ_nbf5I9KOmaMLzOQ7fXPf61JrYe6S75gFYSaM5FXZz25VaWoWIh-29yXALBnDzcSii4Kj8hpQ0IFgLZPQGQ4L2kDfWe-rn5lAFwIS8'; 
    this.setState({ loading: this.state.loading = false })
  
    axios.get("https://laesperanza.gotelemedicina.co/api/studys/34970404",
      {
        headers: {
          'Authorization': token_go
        }
      })
      .then((response) => {
      console.log(response);
      this.setState({Estudios:response.estudios})
      //this.setState({Pager_Convenios: response.data.pager.items})
      //this.setState({pager_infoconvenio: response.data.pager.showData})
      //this.Selectdrop()

      if(this.state.Estudios.length==0){
        $('#card_modulos').hide();    
       
      }else{
        $('#card_modulos').show();      
       
      }

      this.setState({ loading: this.state.loading = true })
    })
    .catch((error) => {
      console.log(error);
    })

  }


  render(){

    const {form} = this.state;

    return(
        
       
        <div className="content-wrapper" id="wrapper">
            
            
              <div className="content-header">
                <div className="container-fluid text-center">
                <img  src={LogoCLEM} alt="Logo CLEM" height="100" className="brand-image img-rounded" />
                <br></br><br></br>
                <b>Ingresos a la institución</b>
                </div>{/* /.container-fluid */}
              </div>
              
              <div className="d-flex justify-content-center">          
                    
                  {this.state.loading?false: <ReactBootStrap.Spinner animation="grow" style={{ width: "4rem", height: "4rem" }} />}
            
              </div>


              <div className="card" id="card_historias">              

                  <div className="row d-flex justify-content-center">

                      <div className="col-md-10">

                        <div className="card card-primary card-outline card-tabs">

                            <div className="card-body" > 

                                <div className="card">

                                    <li  className="list-group-item">
                                    
                                        <div className="d-flex justify-content-center"> 
                                            
                                            <div className="col-md-2 " align="left">
                                                    
                                                <div id="imgcard_estacion" ><b>Clinica La Esperanza de Monteria</b></div> 
                                                      
                                            </div>

                                            <div className="col-md-10 text-left">

                                                <div id="imgcard_estacion"><b>Consulta externa</b></div>
                                                <h6 className="card-title" id="imgcard_estacion" align="left">10/06/2023</h6>
                                                
                                                                              
                                            </div>
                                            
                                            
                                        </div>
                                      
                                    </li>
                                
                                </div>
                              

                                <div className="card"  >

                                    <li  className="list-group-item">
                                    
                                        <div className="d-flex justify-content-center"> 
                                            
                                            <div className="col-md-2 " align="left">

                                                <div id="imgcard_estacion" ><b>Clinica La Esperanza de Monteria</b></div>    
    
                                            </div>

                                            <div className="col-md-10 text-left">

                                                <div id="imgcard_estacion" ><b>Hospitalaria</b></div>
                                                <h6 className="card-title" id="imgcard_estacion" align="left">07/08/2024</h6>
                                                
                                                                              
                                            </div>

                                            
                                        </div>
                                      
                                    </li>
                                
                                </div>

                            </div>

                            

                        </div>

                      </div>
                  
                  </div>

              </div>

              {/*<div className="embed-container">

                    <iframe className="embed-responsive-item" allowfullscreen
                                      src={url_epicrisis}>
                    </iframe>


              </div>*/}

              <div className="modal fade" id="modal_estaciones" >
                    <div className="modal-dialog  modal-dialog-centered">
                      <div className="modal-content">
                        
        
                        <div className="modal-body"> 

                        {this.state.Estaciones.map(estacion=>{
                          return(      
                                              
                                            
                          <div className="card" onClick={()=>{this.Seleccionar_Estacion(estacion);this.handleModal_solicitud_prestamo()}}>

                              <div className="d-flex justify-content-center">                                               
                                  
                                  <div className="col-md-2" align="left">
                                    <img src={Logoestaciones} id="imgcard_estacion" className="brand-image img-rounded" alt="..." />
                                  </div>
                                  <div className="col-md-7">
                                   

                                      <h6 className="card-title" id="imgcard_estacion" align="left"><b>{(estacion.name).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}</b></h6>
                                      <p className="card-text" id="textcard_estacion" align="left">{(estacion.address).trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))}  </p>
                                      
                                                                 
                                  </div>
                                  <div className="col-md-3">                     

                                     
                                      <p className="card-text" id="stokcard_estacion">{estacion.stock + " Disponibles"}  </p>
                                      
                                                                 
                                  </div>

                              </div>  
                      
                          </div>  

                           )
                          }
                          )}         
                      
                        </div>            
        
                      </div>  
                    </div>
              </div>


              <div className="modal fade" id="modal_solicitud_prestamo" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-center" id="staticBackdropLabel">Solicitud de prestamo - {form.nom_estacion}</h5>
                      
                    </div>
                    <div className="modal-body">

                      <div className="row" id="datos_soli">

                        <div className="col-md-6">
      
                            <div className="form-group">                    
                            
                              <input type="date" className="form-control"  name="fecha_prestamo" value={form.fecha_prestamo} min={form.fecha_prestamo} onChange={this.handleChange} />               
                            </div>
      
                        </div>
      
                        <div className="col-md-6">
      
                            <div className="form-group">                    
                              <label >Fin:</label>
                              <input type="time" className="form-control" name="hora_prestamo" value={form.hora_prestamo} onChange={this.handleChange} />                    
                            </div>
      
                        </div>
            
                    </div> 

                    <div className="col-md-12">

                            <div className="form-group">                    
                                    <label >Observación:</label>                           
                                    <textarea className="form-control" maxLength="100"  rows="2" id="obser" name="obser" value={form.obser} onChange={this.handleChange}  ></textarea>
                                    
                            </div>

                          </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                      <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>
                      <button type="button" className="btn btn-primary"  disabled={!this.state.active_button} onClick={()=> this.Crear_solicitud()} > {this.state.loading_button?false:<ReactBootStrap.Spinner animation="border" size="sm" />}  Solicitar </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal fade" id="myModal_info" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h6 className="modal-title text-center" id="staticBackdropLabel">Información de la App</h6>
                      
                    </div>
                    <div className="modal-body">

                        <div className="row">
                            <div className="col-md-6">
                              <div className="form-group text-blue">
                                <h2>App Bisinú</h2>
                                <p>Versión: 1.0.1</p>
                                <b> Contactanos: desarrolloyasesorias.ideas@gmail.com</b>
                              </div>                            
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <img src={LogoBisinu} width={200} />
                              </div>                            
                            </div>
                        </div>
                        <br /> <p className="text-blue"> Copyright © 2022; Ingenieria, Desarrollos y Asesorias-IDEAS <br />
                        Todos los derechos reservados.</p>                     
                    </div>
                   
                  </div>
                </div>
            </div>



            
        </div>

        

    
    )

  }
}

export default Historias_clinica;   